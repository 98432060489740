import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { getAnswersListApi, inactiveAnswersApi } from '../services/questionsService';
import { getQuestionsApi } from '../services/questionsService';
import '../scss/QuestionsTable.scss';
//Imports de components
import {
  MenuButton,
  BackButton,

  Table,
  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  GridRow,
  GridCell,
  TextMedium,
  Checkbox,
  Button,
  Dialog,
  InputFieldOutlined,
  TextSmall,
  InfiniteScroll,
  SelectList
} from '../components';

var timeout;
var timeoutScroll;


const QuestionsTable = (props) => {
  const [state, setState] = React.useState(true);
  const [isInternal, setIsInternal] = React.useState(
    parseInt(props.match.params.internal) > 0 ? true : false
  );
  const [hasFixedCategory, setHasFixedCategory] = React.useState(
    props.match.params.category ? true : false
  );
  const [hasFixedQuestion, setHasFixedQuestion] = React.useState(
    props.match.params.questionid ? true : false
  );
  const [eventId, setEventId] = React.useState(parseInt(props.match.params.id));

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  //Dialog control
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

  const [answerList, setAnswerList] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [totalPageIndex, setTotalPageIndex] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(30);
  const [isScrolling, setIsScrolling] = React.useState(false);
  const [statusList, setStatusList] = React.useState([]);
  const [statusListValue, setStatusListValue] = React.useState();
  const [questionsList, setQuestionsList] = React.useState([]);
  const [questionsListValue, setQuestionsListValue] = React.useState(props.match.params.questionid);
  const [categoriesList, setCategoriesList] = React.useState([]);
  const [categoriesListValue, setCategoriesListValue] = React.useState(props.match.params.category);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [autoRefresh, setAutoRefresh] = React.useState(false);

  React.useEffect(() => {
    //Trava para impedir que acesso remoto possa visualizar recursos do sistema
    if (isInternal) {
      if (
        !props.user ||
        props.user.id === 0 ||
        !props.event ||
        props.event.id === 0
      ) {
        props.history.replace("/");
      }
    }

    setOpenLoading(true);
    getQuestionsApi(eventId)
      .then(res => {
        const _questions = [];
        const def = {
          value: null,
          text: getDic("todos")
        }
        _questions.push(def);
        res.questions.forEach(quest => {
          const obj = {
            value: quest.id,
            text: quest.question
          }
          _questions.push(obj);
        });

        var _question = _questions[0];
        if (hasFixedQuestion) {
          _question = _questions.find(quest => {
            return quest.value === parseInt(props.match.params.questionid);
          });
        }
        setQuestionsListValue(_question.value);
        setQuestionsList(_questions);

        var _category = res.categories[0];
        if (hasFixedCategory) {
          _category = res.categories.find(cat => {
            return cat.value === props.match.params.category;
          });
        }
        setCategoriesListValue(_category.value);
        setCategoriesList(res.categories);

      })
      .catch(err => {
        console.log("Erro getQuestionsApi", err);
      })
      .finally(() => {
        //Monta lista de ativos e inativos
        const _statusList = [];
        const _opt1 = {
          value: null,
          text: getDic("todos")
        }
        _statusList.push(_opt1);

        const _opt2 = {
          value: "active",
          text: getDic("ativo")
        }
        _statusList.push(_opt2);

        const _opt3 = {
          value: "inactive",
          text: getDic("inativo")
        }
        _statusList.push(_opt3);

        setStatusList(_statusList);
        setStatusListValue(_statusList[0].value);

        getAnswersList(true);
      });

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutScroll);
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    }
  }, []);

  const getAnswersList = async (newSearch = false) => {
    try {
      setOpenLoading(true);
      setLoadingMessage(getDic("carregando"));

      clearTimeout(timeout);

      var _pageIndex = pageIndex;
      var _totalPageIndex = totalPageIndex;
      var _answerList = answerList;
      var _hasMore = hasMore;

      if (newSearch) {
        setAnswerList([]);
        setPageIndex(0);
        setTotalPageIndex(0);
        setHasMore(true)
        _pageIndex = 0
        _totalPageIndex = 0;
        _answerList = [];
        _hasMore = true;
      }

      //Fazer a busca apenas se houver mais dados
      if (_hasMore && _pageIndex <= _totalPageIndex) {
        var index = _pageIndex + 1;

        await getAnswersListApi(
          eventId,
          index,
          pageSize,
          categoriesListValue ? categoriesListValue : null,
          questionsListValue ? questionsListValue : null,
          statusListValue ? statusListValue : null,
          startDate ? startDate : null,
          endDate ? endDate : null,
        )
          .then(res => {

            var finalList = _answerList.concat(res.awnswers);

            setAnswerList(finalList);
            setPageIndex(index);
            setTotalPageIndex(res.totalPages);
          })
          .catch(err => {
            setAlertOpen(true);
            setAlertTitle(getDic("erro"));
            setAlertMessage(getDic("sem-conexao"));
          })
          .finally(() => {
            setOpenLoading(false);

            timeout = setTimeout(() => {
              autoRefreshControl();
            }, 60000);
          });
      }
      else if (hasMore) {
        setHasMore(false);

        timeout = setTimeout(() => {
          autoRefreshControl();
        }, 60000);
      }
      else {
        timeout = setTimeout(() => {
          autoRefreshControl();
        }, 60000);

        return;
      }
    } catch (error) {
      setAlertOpen(true);
      setAlertTitle(getDic("erro"));
      setAlertMessage(getDic("sem-conexao"));
    } finally {
      setOpenLoading(false);
    }
  }

  const inactiveAnswers = () => {
    var answersToInactive = answerList.filter(item => {
      return item.activeAnswer === true && item.selected === true;
    });

    if (answersToInactive.length > 0) {

      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      clearTimeout(timeout);

      inactiveAnswersApi(answersToInactive)
        .then(res => {
          var list = answerList;

          answersToInactive.forEach(answer => {
            answerList.find((item, index) => {
              if (item.answerId === answer.answerId) {
                list[index].selected = false;
                list[index].activeAnswer = false;
              }
            });
          });

          setAnswerList(list);
        })
        .catch(err => {
          setAlertOpen(true);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("enviado-erro"));
        })
        .finally(() => {
          setOpenLoading(false);
        });
    }
  }

  const scrollControl = () => {
    //Marca um período em que o scroll foi ativado
    //Para evitar que a página faça scroll enquanto o usuário estiver consultando mensagens anteriores
    clearTimeout(timeoutScroll);

    setIsScrolling(true);

    timeoutScroll = setTimeout(() => {
      setIsScrolling(false);
    }, 20000); // 1 minuto = 60000
  }

  const autoRefreshControl = () => {
    clearTimeout(timeout);

    if (!isScrolling && autoRefresh)
      getAnswersList(true);

    timeout = setTimeout(() => {
      autoRefreshControl();
    }, 60000);
  }

  return (
    <Container background="#e8eced" align="center">
      <FormRow align="center" background="#e8eced" margin="0px">
        {isInternal ? (
          <div>
            <MenuButton history={props.history} />
            <BackButton history={props.history} color="#212121">
              {getDic("voltar").toUpperCase()}
            </BackButton>

          </div>
        ) : null}

        <FormRow
          minHeight="60px"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow
          minHeight="60px"
          align="flex-end"
          background="#e8eced"
          margin="0px"
          paddingTop="5px"
        >
          <div style={{ paddingBottom: "5px", paddingRight: "15px", maxWidth: "180px" }}>
            <Checkbox
              text="Auto Refresh"
              checked={autoRefresh}
              onChange={() => {
                var change = autoRefresh === true ? false : true;
                setAutoRefresh(change);
              }}
            />
          </div>
          <div style={{ paddingBottom: "5px", paddingRight: "10px", maxWidth: "180px" }}>
            <InputFieldOutlined
              title={`${getDic("data")} ${getDic("inicio")}`}
              type="datetime-local"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
            />
          </div>
          <div style={{ paddingBottom: "5px", paddingRight: "10px", maxWidth: "180px" }}>
            <InputFieldOutlined
              title={`${getDic("data")} ${getDic("fim")}`}
              type="datetime-local"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
            />
          </div>
          <div style={{ paddingBottom: "5px", paddingRight: "10px", maxWidth: "400px" }}>
            <SelectList
              title="Status"
              width="160px"
              maxWidth="160px"
              value={statusListValue}
              menuItens={statusList}
              onChange={event => setStatusListValue(event.target.value)}
            />
          </div>
          {!hasFixedQuestion && (
            <div style={{ paddingBottom: "5px", paddingRight: "10px", maxWidth: "400px" }}>
              <SelectList
                title={getDic("pergunta")}
                width="160px"
                maxWidth="160px"
                value={questionsListValue}
                menuItens={questionsList}
                onChange={event => setQuestionsListValue(event.target.value)}
              />
            </div>
          )}
          {!hasFixedCategory && (
            <div style={{ paddingBottom: "5px", paddingRight: "10px", maxWidth: "400px" }}>
              <SelectList
                title={getDic("categoria")}
                width="160px"
                maxWidth="160px"
                value={categoriesListValue}
                menuItens={categoriesList}
                onChange={event => setCategoriesListValue(event.target.value)}
              />
            </div>
          )}
          <div style={{ paddingBottom: "5px", paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="green"
              textColor="white"
              onClick={() => getAnswersList(true)}
            >
              {getDic("buscar")}
            </Button>
          </div>
          <div style={{ paddingBottom: "5px", paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              onClick={() => {
                var answersToInactive = answerList.filter(item => {
                  return item.activeAnswer === true && item.selected === true;
                });

                if (answersToInactive.length > 0) {
                  setDialogConfirmOpen(true);
                }
              }}
            >
              {getDic("inativar") + " " + getDic("selecionados")}
            </Button>
          </div>
        </FormRow>

        <FormColumn align="center" background="#e8eced" margin="0px" padding="5px">
          {answerList.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              dataLength={answerList.length}
              height={"73vh"}
              next={() => {
                getAnswersList();
              }}
              hasMore={hasMore}
              onScroll={() => scrollControl()}
            >
              <GridRow backgroundColor="#d9d9d9">
                <GridCell width="5%" border top first >
                  <Checkbox
                    checked={selectAll}
                    onChange={() => {
                      if (selectAll) {
                        //Passando para false
                        var change = answerList;
                        change.forEach((item, i) => {
                          change[i].selected = false;
                        });
                        setSelectAll(false);
                        setAnswerList(change);
                      }
                      else {
                        //Passando para true
                        var change = answerList;
                        change.forEach((item, i) => {
                          change[i].selected = true;
                        });
                        setSelectAll(true);
                        setAnswerList(change);
                      }
                    }}
                  />
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("categoria").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="15%" border top>
                  <TextMedium>{getDic("pergunta").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="22%" border top>
                  <TextMedium>{getDic("mensagem").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("data").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("hora").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="8%" border top>
                  <TextMedium>STATUS</TextMedium>
                </GridCell>
              </GridRow>

              {answerList.map((item, i) => {
                return (
                  <GridRow
                    key={item.answerId}
                    backgroundColor={item.activeAnswer ? "white" : "#fcacac"}
                    autoColor={item.activeAnswer ? i : null}
                  >
                    <GridCell width="5%" border first>
                      <Checkbox
                        checked={item.selected}
                        onClick={() => {
                          var change = answerList;
                          change[i].selected = change[i].selected === true ? false : true;
                          setState(state === true ? false : true);
                          setAnswerList(change);
                        }}
                      />
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.questionCategory}</TextMedium>
                    </GridCell>
                    <GridCell width="15%" border>
                      <TextMedium>{item.question}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.answerLogin}</TextMedium>
                    </GridCell>
                    <GridCell width="22%" border>
                      <TextMedium>{item.userAnswer}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.answerDate}</TextMedium>
                    </GridCell>
                    <GridCell width="10%" border>
                      <TextMedium>{item.answerHour}</TextMedium>
                    </GridCell>
                    <GridCell width="8%" border>
                      {item.activeAnswer ? (
                        <TextMedium>{getDic("ativo")}</TextMedium>
                      ) : (
                        <TextMedium>{getDic("inativo")}</TextMedium>
                      )}
                    </GridCell>
                  </GridRow>
                );
              })}
            </InfiniteScroll>
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </FormColumn>

        <Alert
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          onClick={() => setAlertOpen(false)}
          title={alertTitle}
          message={alertMessage}
        />

        <Dialog
          open={dialogConfirmOpen}
          onClose={() => setDialogConfirmOpen(false)}
          title={getDic("atencao")}
          textButtonYes={getDic("inativar")}
          onClickYes={() => {
            if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
              inactiveAnswers();
              setDialogConfirmOpen(false);
              setDialogConfirmKey("");
            }
            else {
              setDialogConfirmKeyErrorMessage(true);
              setTimeout(() => {
                setDialogConfirmKeyErrorMessage(false);
              }, 3000); // 1 minuto = 60000
            }
          }}
          textButtonNo={getDic("cancelar")}
          onClickNo={() => {
            setDialogConfirmOpen(false);
            setDialogConfirmKey("");
          }}
        >
          <FormRow>
            <TextSmall>
              {getDic("confirmacao-de-acao")}
            </TextSmall>
          </FormRow>
          <FormRow align="center" paddingTop="5px">
            <InputFieldOutlined
              key="password"
              value={dialogConfirmKey}
              onChange={event => setDialogConfirmKey(event.target.value)}
            />
          </FormRow>
          {dialogConfirmKeyErrorMessage ? (
            <TextSmall color="red">{getDic("erro")}</TextSmall>
          ) : (null)}
        </Dialog>

        <Loading open={openLoading} message={loadingMessage} />
      </FormRow>
    </Container>
  )
}

function mapStateToProps(state) {

  return {
    event: state.event,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(QuestionsTable);
