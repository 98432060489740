import React from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";

const getStatusUser = (date) => {
  const dt = new Date();
  dt.setMinutes(dt.getMinutes() - 255);
  if (new Date(date) > dt) return true;
  else return false;
};

const ListView = ({ listData }) => {
  const [pages, setPages] = React.useState(0);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [itensPerPage, setItensPerPage] = React.useState(10);
  const [currenteRange, setCurrentRange] = React.useState(-1);
  const [filterByStatus, setFilterByStatus] = React.useState(1);

  const [searchTerm, setSearchTerm] = React.useState("");

  React.useEffect(() => {
    if (listData && listData.length > 0) {
      setPages(
        Math.round(
          listData
            .filter((e) => {
              if (
                filterByStatus == 1 &&
                e.accessDate &&
                getStatusUser(e.accessDate.toDate())
              )
                return e;
              if (
                filterByStatus == 2 &&
                e.accessDate &&
                !getStatusUser(e.accessDate.toDate())
              )
                return e;
              if (filterByStatus == 3) return e;
            })
            .filter((e) => JSON.stringify(e).indexOf(searchTerm) > -1).length /
          10
        )
      );
    }
  }, [listData, currenteRange, filterByStatus, searchTerm]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Lista de Usuários</CardTitle>
          {listData && listData.length > 0 ? (
            <div
              data-test="datatable"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div className="row">
                <div
                  data-test="datatable-entries"
                  className="col-sm-10 col-md-10"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    data-test="datatable-select"
                    className="dataTables_length bs-select"
                    style={{ display: "flex" }}
                  >
                    <label style={{ marginBottom: 0, fontWeight: 400 }}>
                      Status
                    </label>
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      style={{ marginLeft: "0.5rem" }}
                      onChange={(e) => {
                        setCurrentPage(0);
                        setCurrentRange(-1);
                        setFilterByStatus(e.target.value);
                      }}
                    >
                      <option value={1}>Online</option>
                      <option value={2}>Offline</option>
                      <option value={3}>Todos</option>
                    </select>
                  </div>
                </div>
                <div
                  data-test="datatable-search"
                  className="col-sm-2 col-md-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    data-test="datatable-input"
                    className="mdb-datatable-filter flex-row"
                  >
                    <input
                      className="form-control form-control-sm ml-0 my-1"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div data-test="datatable-table" className="col-sm-12">
                  <div data-test="table" className="table-responsive">
                    <table
                      entries={2}
                      className="table table-bordered table-striped dataTable"
                    >
                      <thead data-test="datatable-head">
                        <tr>
                          <th className="sorting">Login</th>
                          <th className="sorting">LoginID</th>
                          <th className="sorting">Config Id</th>
                          <th className="sorting">Scheduled Id</th>
                          <th className="sorting">Page</th>
                          <th className="sorting">Status</th>
                        </tr>
                      </thead>
                      <tbody data-test="table-body">
                        {listData
                          .filter((e) => {
                            if (
                              filterByStatus == 1 &&
                              e.accessDate &&
                              getStatusUser(e.accessDate.toDate())
                            )
                              return e;
                            if (
                              filterByStatus == 2 &&
                              e.accessDate &&
                              !getStatusUser(e.accessDate.toDate())
                            )
                              return e;
                            if (filterByStatus == 3) return e;
                          })
                          .filter(
                            (e) =>
                              JSON.stringify(e)
                                .toUpperCase()
                                .indexOf(searchTerm.toUpperCase()) > -1
                          )
                          .slice(
                            currentPage == 0 ? 0 : currentPage * 10,
                            currentPage == 0
                              ? currentPage + itensPerPage
                              : currentPage * 10 + itensPerPage
                          )
                          .map((e, index) => (
                            <tr key={index}>
                              <td>{e.login}</td>
                              <td>{e.loginId}</td>
                              <td>{e.configId}</td>
                              <td>{e.scheduleId}</td>
                              <td>{e.page}</td>
                              <td>
                                {e.accessDate &&
                                  getStatusUser(e.accessDate.toDate()) &&
                                  e.accessDate != undefined ? (
                                  <span
                                    style={{
                                      background: "#34c38f",
                                      color: "#ffffffab",
                                      padding: 4,
                                      borderRadius: 15,
                                      fontSize: 14,
                                    }}
                                  >
                                    online
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      background: "#74788d",
                                      color: "#ffffffab",
                                      padding: 4,
                                      borderRadius: 15,
                                      fontSize: 14,
                                    }}
                                  >
                                    offline
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  data-test="datatable-info"
                  className="col-sm-12 col-md-5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="dataTables_info"
                    role="status"
                    aria-live="polite"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td
                      style={{
                        fontSize: 13,
                        fontWeight: 600,
                        paddingLeft: 3,
                      }}
                    >
                      Exibindo página {currentPage + 1} de {pages - 1} com total
                      de{" "}
                      {
                        listData
                          .filter((e) => {
                            if (
                              filterByStatus == 1 &&
                              e.accessDate &&
                              getStatusUser(e.accessDate.toDate())
                            )
                              return e;
                            if (
                              filterByStatus == 2 &&
                              e.accessDate &&
                              !getStatusUser(e.accessDate.toDate())
                            )
                              return e;
                            if (filterByStatus == 3) return e;
                          })
                          .filter(
                            (e) => JSON.stringify(e).indexOf(searchTerm) > -1
                          ).length
                      }{" "}
                      usuários
                    </td>
                  </div>
                </div>
                <div
                  data-test="datatable-pagination"
                  className="col-sm-12 col-md-7"
                >
                  <div className="dataTables_paginate">
                    <ul data-test="pagination" className="pagination">
                      <li
                        data-test="enabled page-item"
                        className={`${currentPage == 0 ? "disabled" : "enabled"
                          } page-item`}
                      >
                        <a
                          data-test="page-link"
                          aria-label="Previous"
                          className="page-link page-link"
                          onClick={() => {
                            setCurrentPage(0);
                            setCurrentRange(-1);
                          }}
                        >
                          <span>First</span>
                        </a>
                      </li>
                      <li
                        data-test="enabled page-item"
                        className={`${currentPage == 0 || currenteRange == -1
                          ? "disabled"
                          : "enabled"
                          } page-item`}
                      >
                        <a
                          data-test="page-link"
                          aria-label="Previous"
                          className="page-link page-link"
                          onClick={() => {
                            setCurrentPage(currentPage - 1);
                            setCurrentRange(currenteRange - 1);
                          }}
                        >
                          <span>...</span>
                        </a>
                      </li>
                      {[...Array(pages)].map((_, ci) => (
                        <li
                          data-test="page-item"
                          className={
                            currentPage == ci
                              ? "disabled page-item"
                              : "active page-item"
                          }
                          key={ci}
                          style={
                            ci <= currenteRange + 5 && ci > currenteRange
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <a
                            data-test="page-link"
                            className="page-link page-link"
                            onClick={() => {
                              setCurrentPage(ci);
                            }}
                          >
                            {ci + 1}
                          </a>
                        </li>
                      ))}
                      <li
                        data-test="enabled page-item"
                        className={`${currenteRange >= pages - 6
                          ? "disabled page-item"
                          : "enabled page-item"
                          }`}
                      >
                        <a
                          data-test="page-link"
                          aria-label="Previous"
                          className="page-link page-link"
                          onClick={() => {
                            setCurrentPage(currenteRange + 2);
                            setCurrentRange(currenteRange + 1);
                          }}
                        >
                          <span>...</span>
                        </a>
                      </li>

                      <li
                        data-test="page-item"
                        className={`${currenteRange >= pages - 6
                          ? "disabled page-item"
                          : "enabled page-item"
                          }`}
                      >
                        <a
                          data-test="page-link"
                          aria-label="Next"
                          className="page-link page-link"
                          onClick={() => {
                            setCurrentPage(pages - 1);
                            setCurrentRange(pages - 6);
                          }}
                        >
                          <span>Last</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            [1, 2, 3].map((e, i) => (
              <table
                className="table table-responsive w-100 d-block d-md-table"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <tr key={"_tr_" + i}>
                  <td>
                    <Col sm="12">
                      <Skeleton count={1} />
                    </Col>
                  </td>
                  <td>
                    <Col sm="12">
                      <Skeleton count={1} />
                    </Col>
                  </td>
                  <td>
                    <Col sm="12">
                      <Skeleton count={1} />
                    </Col>
                  </td>
                  <td>
                    <Col sm="12">
                      <Skeleton count={1} />
                    </Col>
                  </td>
                  <td>
                    <Col sm="12">
                      <Skeleton count={1} />
                    </Col>
                  </td>
                  <td>
                    <Col sm="12">
                      <Skeleton count={1} />
                    </Col>
                  </td>
                  <td>
                    <Col sm="12">
                      <Skeleton count={1} />
                    </Col>
                  </td>
                </tr>
              </table>
            ))
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ListView;
