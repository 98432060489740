import axios from "axios";
import { store } from "../store";
import { setToken, setDate } from "../store/actions";
import moment from "moment";
import * as tokenModel from "../models/tokenModel";
import { createDateModel } from "../models/dateModel";

export function getUrl() {
  const user = store.getState().user;
  const isDeveloperPath = window.location.href.includes("?dev") ? true : false;
  const isDeveloper = user && user.isDeveloper ? user.isDeveloper : false;
  const changeHost = () => {
    switch (window.location.host) {
      case "hall.inteegra.com.br":
        return "https://hall-gateway.inteegra.com.br";
      case "hall-ohio.inteegra.com.br":
        return "https://hall-gw-ohio.inteegra.com.br";
      case "dev-hall.inteegra.com.br":
        return "https://dev-hall-gateway.inteegra.com.br";
      case "localhost:3000":
        return "https://hall-gateway.inteegra.com.br";
      default:
        return "https://hall-gateway.inteegra.com.br";
    }
  };

  const _serverUrl = changeHost();

  // Produção
  var urls = {
    security: "https://api-security.inteegra.com.br/security/api/",
    // security: 'https://api-security.inteegra.com.br/api/',
    files: "https://s3.amazonaws.com/bucket.aws.public/",
    hall: `${_serverUrl}/hall/api/`,
    direct: "https://hall-gateway.inteegra.com.br/",
    fileServer: `${_serverUrl}/upload/file/api/`,
    excelReports: `${_serverUrl}/report/`,
    validateCrm: `${_serverUrl}/consultacrm/`,
    chat: `${_serverUrl}/chat`,
    traceability: `${_serverUrl}/heatmap/api/`,
    feeds: `${_serverUrl}/feeds/`,
    // feeds: `${_serverUrl}/feeds-npgsql/`,
    syncData: `${_serverUrl}/configuration/api/`,
    transmission: `${_serverUrl}/transmission/`,
    base: `${_serverUrl}/`,
    whatsapp: "https://whatsapp-api.sistemasinteegra.com.br/",
    // firebase: "http://127.0.0.1:5001/inteegra-ping/us-central1/",
    firebase: "https://hall-gateway.inteegra.com.br/firebase/",
    zoomSdk: "https://zoom.inteegrav2.com.br/",
    // campaign: "http://localhost:8080/",
    campaign: "https://hall-gateway.inteegra.com.br/message-trigger/",
    corsAnywhere: "https://allow-any-origin-iframe.sistemasinteegra.com.br/",
    externalApi: "https://api-externa.inteegra.com.br/hall/api/",
    externalApiBase: "https://api-externa.inteegra.com.br/",
    gateway: `${process.env.REACT_APP_URL_ADMIN_PLUS}/`,
  };
  // var urls = {
  //     security: 'https://api-security.inteegra.com.br/security/api/',
  //     // security: 'https://api-security.inteegra.com.br/api/',
  //     files: 'https://s3.amazonaws.com/bucket.aws.public/',
  //     hall: 'https://hall-gateway.inteegra.com.br/hall-api-npgsql/api/',
  //     fileServer: 'https://hall-gateway.inteegra.com.br/upload-npgsql/file/api/',
  //     excelReports: 'https://hall-gateway.inteegra.com.br/hall-reports-npgsql/',
  //     validateCrm: 'https://hall-gateway.inteegra.com.br/consultacrm/',
  //     chat: 'https://hall-gateway.inteegra.com.br/chat',
  //     traceability: 'https://hall-gateway.inteegra.com.br/heat-map-npsql/api/',
  // }

  if (isDeveloper || isDeveloperPath) {
    //Desenvolvimento
    var urls = {
      security: "https://api-security.inteegra.com.br/security/api/",
      // security: 'https://api-security.inteegra.com.br/api/',
      files: "https://s3.amazonaws.com/bucket.aws.public/",
      hall: "http://localhost:5000/api/",
      fileServer: "http://localhost:5000/file/api/",
      excelReports: "https://localhost:6001/",
      validateCrm: `${_serverUrl}/consultacrm/`,
      chat: `${_serverUrl}/chat`,
      traceability: "http://localhost:5000/api/",
      syncData: "http://localhost:5000/api/",
      firebase: "http://localhost:5001/inteegra-ping/us-central1/",
      base: "http://localhost:5000/api/",
      whatsapp: "http://localhost:5000/api/",
      zoomSdk: "https://zoom.inteegrav2.com.br/",
      campaign: "http://localhost:8080/",
      corsAnywhere: "https://allow-any-origin-iframe.inteegrav2.com.br/",
      gateway: `${process.env.REACT_APP_URL_ADMIN_PLUS}/`,
    };
  }

  return urls;
}

export function getToken(email) {
  return new Promise((resolve, reject) => {
    // const _isRegistration = window.location.href.includes("/registration") ? true : false;
    // const _userToken = store.getState().token;
    // if (!_isRegistration && _userToken && _userToken.token) {
    //   resolve(_userToken.token);
    //   return;
    // }

    const _event = store.getState().event;
    const _user = store.getState().user;
    const method = `${getUrl().firebase}hallauthenticationbyuseremailv2?eventId=${_event && _event.id ? _event.id : ""
      }&email=${email ? email : _user && _user.email ? _user.email : ""}`;

    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getTokenByEventId", err);
        reject(err);
      });
  });
}

export function getTokenByEventId(eventId) {
  return new Promise((resolve, reject) => {
    const method = `${getUrl().firebase
      }hallauthenticationbyeventidv2?eventId=${eventId}`;

    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getTokenByEventId", err);
        reject(err);
      });
  });
}

function getServerDateApi() {
  return new Promise((resolve, reject) => {
    const method = getUrl().hall + "event/traceability/calendar";

    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        console.log("Erro getServerDateApi", err);
        reject(err);
      });
  });
}

export async function getDate() {
  return new Promise(async (resolve, reject) => {
    const storeDate = store.getState().date;

    var strServerDate = storeDate.serverDate;
    var strLocalDate = storeDate.localDate;

    var localDate = moment(strLocalDate);
    var currentDate = moment();
    var diffMinutes = currentDate.diff(localDate, "minutes");
    diffMinutes = diffMinutes < 0 ? 31 : diffMinutes;

    //Buscar a data do servidor no momento do login ou após 30 minutos
    if (
      !storeDate.serverDate ||
      storeDate.serverDate === "" ||
      (diffMinutes && diffMinutes > 30)
    ) {
      getServerDateApi()
        .then((res) => {
          strServerDate = res;
        })
        .catch(() => {
          resolve(moment().format());
        })
        .finally(() => {
          const saveDate = createDateModel(strServerDate, currentDate);
          store.dispatch(setDate(saveDate));
          resolve(strServerDate);
        });
    } else {
      var diffInMilliseconds = currentDate.diff(localDate, "seconds");
      var serverDate = moment(strServerDate)
        .add(diffInMilliseconds, "seconds")
        .format();
      resolve(serverDate);
    }
  });
}
