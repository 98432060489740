import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import { getCountOnlineGuests } from "../../services/guestService";
import ModalController from "./ModalController";

const series1 = [{ name: "", data: [12, 14, 8, 3, 8, 0, 9, 12, 15, 8, 21] }];
const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#f1b44c"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: {
    enabled: false,
    fixed: { enabled: !1 },
    x: { show: !1 },
    marker: { show: !1 },
  },
};

const series2 = [
  { name: "", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
];
const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#3452e1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: {
    enabled: false,
    fixed: { enabled: !1 },
    x: { show: !1 },
    marker: { show: !1 },
  },
};

const series3 = [
  { name: "", data: [35, 53, 93, 47, 54, 24, 47, 12, 14, 19, 95] },
];
const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#50a5f1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: {
    enabled: false,
    fixed: { enabled: !1 },
    x: { show: !1 },
    marker: { show: !1 },
  },
};

const MiniWidget = ({ listData }) => {
  const [usersTotal, setUsersTotal] = React.useState(0);
  const [modalType, setModalType] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [checked, setChecked] = React.useState(0);

  const getStatusUser = (date) => {
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() - 255);
    if (new Date(date) > dt) return true;
    else return false;
  };
  let generalData = [
    {
      title: "Total de Cadastros",
      icon: "mdi mdi-account-plus",
      color: "warning",
      value: usersTotal,
      desc: "Cadastros",
      series: series1,
      options: options1,
      arrowUpDown: "",
    },
    {
      title: "Total de Acessos",
      icon: "mdi mdi-account-multiple",
      color: "primary",
      value: listData && listData.length > 0 ? listData.length : 0,
      desc: "Acessos",
      series: series2,
      options: options2,
      arrowUpDown: "",
      iconsPack: ["mdi mdi-file-excel"],
      iconsPackColor: ["#00b504"],
      actionType: ["OPEN_TOTAL_ACCESS_EXCEL"],
    },
    {
      title: "Usuários Online",
      icon: "mdi mdi-account",
      color: "info",
      value:
        listData && listData.length > 0
          ? listData.filter(
            (e) =>
              e.accessDate &&
              getStatusUser(e.accessDate.toDate()) &&
              e.accessDate != undefined
          ).length
          : 0,
      desc: "Online",
      series: series3,
      options: options3,
      arrowUpDown: "",
      iconsPack: ["mdi mdi-chart-pie", "mdi mdi-file-excel"],
      iconsPackColor: ["#b50090", "#00b504"],
      actionType: ["OPEN_USERS_ONLINE_CHART", "OPEN_USERS_ONLINE_EXCEL"],
    },
  ];

  React.useEffect(() => {
    if (usersTotal == 0) {
      getCountOnlineGuests().then((res) => {
        console.log("pending", res.total);
        setUsersTotal(res.total);
      });
    }
  }, [listData]);

  return (
    <React.Fragment>
      {openModal && (
        <ModalController
          listData={listData}
          modalType={modalType}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}
      {listData && listData.length > 0
        ? generalData.map((report, key) => (
          <Col sm="4" key={key}>
            <Card>
              <CardBody>
                <p className="text-muted mb-4">
                  <i
                    className={
                      report.icon +
                      " h2 text-" +
                      report.color +
                      " align-middle mb-0 me-3"
                    }
                  />{" "}
                  {report.title}{" "}
                  {report.iconsPack &&
                    report.iconsPack.map((e, i) => (
                      <i
                        key={i}
                        className={e + ` h2 align-middle mb-0 me-3`}
                        style={{
                          color: report.iconsPackColor[i],
                          right: 0,
                          position: "absolute",
                          cursor: "pointer",
                          right: i * 40,
                        }}
                        onClick={() => {
                          setModalType(report.actionType[i]);
                          setOpenModal(true);
                        }}
                      />
                    ))}
                </p>

                <Row>
                  <Col xs="6">
                    <div>
                      <h5>{report.value}</h5>
                      <p className="text-muted text-truncate mb-0">
                        {report.desc} <i className={report.arrowUpDown} />
                      </p>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <div>
                        <ReactApexChart
                          options={report.options}
                          series={report.series}
                          type="area"
                          height={40}
                          className="apex-charts"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))
        : [1, 2, 3].map((e, i) => (
          <Col sm="4" key={i}>
            <Card>
              <CardBody>
                <Col sm="12">
                  <Skeleton count={1} />
                </Col>
                <Col
                  sm="4"
                  style={{
                    height: 80,
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Skeleton count={1} />
                  <Skeleton count={1} />
                </Col>
                <Col sm="2" />
                <Col sm="6">
                  <Skeleton count={1} height={80} />
                </Col>
              </CardBody>
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

export default MiniWidget;

MiniWidget.propTypes = {
  reports: PropTypes.array,
};
